 /*

|-----------------Development Info------------------------|
|=========================================================|
||--  Developer's Name : Shoeb Akhtar                  --||
||--  Date : 26 june 2023                              --||
||--  firm : Voice Sphere                              --||
||--  for  : Aadish technology                         --||
||--  URL  : https://adhish.in/                        --||
|=========================================================|
|---------------------------------------------------------|

	 font-family: 'Montserrat';

nav-top-active     for active topbar org
*/

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;500;600;700&display=swap');
/*  font-family: 'Red Hat Text', sans-serif;
*/
body {
  overflow: hidden;
}

input, select:focus {
  outline: none !important;
  box-shadow: none !important;
}


a {
	text-decoration: none !important;
}
.pt-6 {
  padding-top: 60px;
}
.pt-13 {
	padding-top: 130px;
}
.pb-20{
	padding-bottom: 200px;
}
.pb-10{
	padding-bottom: 100px;
}
.container {
	max-width: 1260px !important;
  //height: 48px !important;
}
form {
	width: 100%;
}
.iuc-bg img {
  z-index: 99;
  position: relative;
  width: 100%;
  max-width: 146px;
}
input:focus:required:invalid {border: 1px solid #E47C67;}
input:required:valid { border: 1px solid   #8086FF; }


.btn-voice-submit {
  width: 100% !important;
  display: inline-block;
  padding: 10px !important;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  border-radius: 8px;
  border: none;
  border: 2px solid #8086FF ;
  background-color: #758AF0 !important;
  color: #ffff !important;
  text-transform: capitalize;
  transition: all .3s ease-in-out;
  height: 50px;
}
.btn-voice-submit:hover { background-color: #8086FF !important; color: #FFFFFF !important; border-color: #8086FF;}
.btn-voice-active { background-color: #8086FF; color: #FFFFFF; border-color: #8086FF;}
.btn-voice { border: 2px solid #8086FF; background-color: #fff; color: #8086FF;}

.signoutbtndesign {
  border: 1px solid #e7ebef;
  background: #fff;
  padding: 0 !important;
  text-align: center !important;
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #1A344D;
}

.signoutbtndesign:hover
.signoutbtndesign:focus
.signoutbtndesign:active{
  background: #fff; /* Set the hover background color to match the default background color */
  color: initial; /* Reset the text color to the default value */
  border-color: #e7ebef; /* Set the border color to match the default border color */
}
.signout-active:active {
  background-color: #fff;
  font-family: Red Hat Text;
  font-weight: 400;
  color: #334E67;
}
.signout-active:hover {
  background-color: #fff;
}

/*------------Chat Documents User Activation-------------*/
.cdu-activation {
  border-bottom: 3px solid transparent;
  transition: all 0.3s ease-in-out;
  border-color: #758af0 !important;
}
.cdu-activation p {
  color: #758af0 !important;
}
.cdu-activation path, .cdu-activation ellipse, .cdu-activation circle {
  stroke: #758af0 !important;
}
/*------------End Chat Documents User Activation-------------*/

/*--------------------------------------------Signup Section ----------------------------------------------*/
.logo-voice {
    background-image: url(../images/image-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    background-color: #2d2e39;
    position: relative;
    /*height: 100vh;  Added to make it take up the entire height */
}

.logo-voice:before {
    content: "";
    background-image: url(../images/logo.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 290px;
    height: 50px;
}

.side-bg:before {
  background: #2d2e39;
  content: "";
  position: absolute;
  height: 100%;
  width: 32%;
  top: 0;
  left: 0;
}
.heading-voice {
	font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 120%;
    color: #334E67;
}
.emial-voice {
	font-family: 'Red Hat Text';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 130%;
	color: #617D97;
	background: rgba(193, 204, 214, 0.2) !important;
}

.form-control {
    width: 100% !important;
    padding: 15px;
}
.form-label {
    margin-bottom: 0.5rem;
    font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #617D97;
}
.signup input::placeholder {
	font-family: 'Red Hat Text';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 130%;
	color: #476481;
}
.custom-button {
  background: #8086FF!important;
  color: #fff !important ;
}
.custom-heading {
  margin-top: 33px !important;
}
.continue-button-height {
  height: 29px;
}
.custom-button {
  margin-top: 33px !important;
}
.email-input {
  margin-bottom: 6.5px !important; 
}

.continue-email-input {
  margin-top: 6.5px !important; 
}


.btn-google-signin {
  margin-bottom: 16px !important;
}

.btn-or {
  margin-top: 16px !important;
}

.google-signup {
	text-align: center;
}
.google-signup a:before {
  content: "";
  background-image: url(../images/google.jpg);
  background-size: auto 45px !important;
  background-repeat: no-repeat;
  background-position: center !important;
  background-size: cover;
  position: absolute;
  top: 3px;
  left: 3px;
  width: 45px !important;
  height: 45px !important;
}
.google-signup a {
	 position: relative;
    background-color: #4285f4 !important;
    border-radius: 2px;
    color: #fff;
    font-weight: 600;
    font-size: 19px;
    font-family: red hat text;
    padding: 12px 10px 12px 55px;
}
.inner-container h6 {
    font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #334E67 !important;
}
.inner-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
/*--------------------------------------------Signup Section end----------------------------------------------*/



/*--------------------------------------------Verification Section ----------------------------------------------*/
.verification-code h5{
	font-family: 'Red Hat Text';
	font-style: normal;
	font-weight: 600;
	font-size: 34px;
	line-height: 120%;
	color: #334E67;
	margin-bottom: 25px;
}
.verification-code {
	height: 100vh;
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
}
.verification-code p{
	font-family: 'Red Hat Text';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 130%;
	color: #334E67;
	margin-bottom: 40px;
}



/*--------------------------------------------Verification Section end----------------------------------------------*/



/*--------------------------------------------Welcome Section----------------------------------------------*/

.verification-code select {
  position: relative;
}

.verification-code select:after {
  content: "\f0d7";
  position: absolute;
  font-family: FontAwesome;
  top: 50%;
  right: 10px; /* Adjust the right property to position the icon as desired */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000; /* Adjust the border properties to style the icon */
  color: red;
  font-size: 26px;
  z-index: 999;
}

.welcome-screen-continue-button {
  background: #8086FF!important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #fff !important;
 }

.icon-upload {
  height: 100px;
  width: 100px;
}

.add-heading {
	font-family: 'Red Hat Text';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 130%;
	color: #617D97;
}
.circle-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: none;
        outline: none;
        cursor: pointer;
        width: 64px;
        height: 64px;
        background-color: #8086FF;
        margin-left: 13px;
        margin-top: 1rem;
    }

.custom-select {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #476481;
  position: relative;
  background-image: url(../images/caret-down-fill.svg);
}

.custom-select::before {
  content: "\f0d7";
  font-family: "Bootstrap Icons";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  color: #476481;
  background-color: #476481;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}
.bg-color {
  background-color: rgba(193, 204, 214, 0.2);
  border: 1px solid rgba(193, 204, 214, 0.4);
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
}
.input-with-placeholder:focus::placeholder {
  color: transparent !important;
}
/* .signUpEmail {
  width: 100%;
  max-width: 345px;
} */
/*--------------------------------------------Welcome Section end----------------------------------------------*/


/*--------------------------------------------Collection Section ----------------------------------------------*/

.collection p {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #334E67;

}


/*--------------------------------------------Collection Section ----------------------------------------------*/





/*--------------------------------------------Upload Profile Section ----------------------------------------------*/



.profile-upload {
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-image: url("../images/add-white.svg");
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.profile-upload-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.profile-outer a {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  color: #334E67;
}

.image-upload {
  text-align: center;
}
.image-upload>input {
  display: none;
}
.image-upload>label {
  cursor: pointer;
}
.image-upload label img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.icon-upload-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.uyp-photo {
  width: 66.66%;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: #C1CCD6;
  border-color: #C1CCD6;
  opacity: var(--bs-btn-disabled-opacity);
}
/*--------------------------------------------Upload Profile Section End----------------------------------------------*/





/*--------------------------------------------Invite User To Collection Section----------------------------------------------*/
body#grey {
  background-color: #F3F5F7;
  padding: 0;
}

.headerSection {
    background-color: #334E67;
    padding: 5px;
    position: relative;
    overflow: hidden;
}
header.headerSectionUser {
  background: #334E67;
  padding: 8px 0;
}

.flex-items-right {
  text-align: right;
}

.user-profile-pic.flex-items-right img {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  line-height: 32px;
  display: block;
}
header.headerSection .row {
  margin: 0;
}
.iuc-bg {
    position: relative;
}
.iuc-bg:before {
  position: absolute;
  content: "";
  background-image: url(../images/header-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  left: -125px;
  display: block;
  height: 46px;
  width: 73%;
  top: -9px;
}
.chat:before {
  left: -100px;
}
.chat-answer-font-size{
  font-size: 17px;
}
section.collectioName {
  padding: 20px 0 20px;
}
.user-icon {
  margin-right: 32px;
}
.collectioName h1 {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 120%;
  color: #334E67;


}

.collectioName p {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #334E67;


}

.formcollection-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  gap: 40px;
  width: 100%;;
  max-width: 1260px;
  height: 53px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(15, 42, 66, 0.1);
  border-radius: 8px;
}
.formcollection-inner .email{ width: 50% !important;}
form .formcollection-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  gap: 40px;
  transition: all 0.2S ease-in-out;
  flex-wrap: wrap;
  height: auto;
}
.formcollection-inner input[type=email] {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  max-width: 370px;
  height: 37px;
  background: rgba(193, 204, 214, 0.2);
  border: 1px solid rgba(193, 204, 214, 0.4);
  border-radius: 4px;
}
.formcollection-inner a {
  opacity: 0;
}
.formcollection-inner:hover{
  background-color: #F5F6FF;
  cursor: pointer;  
}
.formcollection-inner:hover .delete a{
  opacity: 1;  
}

.collect-field {
  margin-left: 20px;
  position: relative;
}
.collect-field:first-child {
  margin: 0;
}
.collect-field input {
  left: -9px;
  opacity: 0;
  position: absolute;
  z-index: 1;
  height: 22px;
  width: 22px;
  top: 4px;
  cursor: pointer;
}
.collect-field label:before {
  content: '';
  position: absolute;
  top: 2px;
  left: -10px;
  width: 22px;
  height: 22px;
  background-image: url(../images/Unselected.png);
  background-repeat: no-repeat;
  border-radius: 50%;
}
.collect-field input:checked~label:before {
  background-image: url(../images/Selected.png);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.collect-field:first-child {
  margin: 0;
  margin-right: 30px;
}
.collect-field label {
  padding-left: 20px;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #1A344D;
}
.addemail {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 4px;
  padding-right: 19px;
  width: 135px;
  height: 34px;
  border: 2px solid #758AF0;
  border-radius: 4px;
  margin-bottom: 35px;
  position: relative;
  transition: all .3s ease-in-out;
}
.addemail:before {
  content: "";
  background: url(../images/add.svg);
  position: absolute;
  top: 9%;
  left: 14px;
  width: 23px;
  height: 23px;
  display: inline-block;
  z-index: 99;
}
.addemail:hover::before {
  background: url("../images/add-white.svg");
}

.userLater {
  margin-bottom: 100px;
  margin-top: 20px;
}
.userLater p {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-align: left;
  color: #819AB0;

}
.userLater a {
  padding: 13px 20px;
  background: #F3F5F7;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  width: 100%;
  line-height: 110%;
  border: 2px solid #8086FF;
  border-radius: 8px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin-left: 10px;
}
.userLater a:hover {
  background: #758AF0;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}
/*--------------------------------------------Invite User To Collection Section End----------------------------------------------*/



/*--------------------------------------------Invite Sent To Collection Section End----------------------------------------------*/

.inner-sent-invite p {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 130%;
  color: #334E67;
  position: relative;
  margin-bottom: 55px;
  display: inline-block;
}
.inner-sent-invite p:before {
  content: "";
  background-image: url(../images/invite-sent.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: -35px;
}

.inner-sent-invite h1 {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 120%;
  text-align: center;
  color: #334E67;
  margin-bottom: 30px;
}

.inner-sent-invite span img {
  margin: 0 auto 0px;
  display: block;
}
span.invite-para {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #334E67;
  width: 90%;
  display: block;
  margin-bottom: 40px;
  text-align: left;
}
a.btn-sent {
  background: #758AF0;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #FFFFFF;
  justify-content: center;
  padding: 16px 20px;
  width: 100%;
  display: block;
  text-align: center;
}

/*--------------------------------------------Invite Sent To Collection Section End----------------------------------------------*/


/*--------------------------------------------knowladgebase Section----------------------------------------------*/
.knowladgebase h1 {
    font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 120%;
    text-align: center;
    color: #334E67;
    margin-bottom: 50px;
    max-width: 400px;
    margin: 0 auto 50px;
}
.ad-heading {
  font-family: 'Red Hat Text';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 130%;
color: #617D97;
}

.type {
  text-align: center;
  padding-left: 55px;
}
.size {
    padding-left: 56px;
}

.ad-heading .document {
  padding-left: 60px;
}
.progress-color { 
  background-color: #00B277 !important; 
}
.percent-position{
  position: relative;
}
.progress-value {
  position: absolute;
  top: -7px;
  right: 120px;
  line-height: 1.5;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #334E67;
}
span.progress.add-docs-progressbar {
  width: 47%;
  height: 8px;
}
span.progress.add-docs-progressbar-1 {
  width: 90%;
  height: 8px;
}
.col-lg-4.single-progress {
  margin: 0 auto;
}
span.progress-value.knb-pv {
  right: 0;
}
.knowladgebase h6 {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 120%;
  color: #334E67;
}
.knowladgebasebuilt h1 {
  margin-bottom: 50px;
  position: relative;
}
.knowladgebasebuilt h1:before {
  content: "";
  position: absolute;
  background: url(../images/invite-sent.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 24px;
  width: 24px;
  top: 7px;
  left: 0;
}


/*--------------------------------------------knowladgebase Section end----------------------------------------------*/




/*--------------------------------------------Add Documents Section----------------------------------------------*/
.add-document .heading h1 {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 130%;
  color: #334E67;

}
.add-document .button a {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 130%;
  color: #334E67;

}
.add-doc-pop-title { border-bottom: none !important;}
.add-doc-pop-title h5{
font-family: Red Hat Text !important;
font-size: 26px !important;
font-weight: 600 !important;
line-height: 31px !important;
letter-spacing: 0em !important;
text-align: left !important;
color: #334E67;
}
.add-doc-closed-pop {
  cursor: pointer;
  width: 24px;
  height: 24px;
}


.input-folderName {
  margin-bottom: 18px !important;
  width: 92px;
  height: 45px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  border: 1px;
  background: linear-gradient(0deg, rgba(193, 204, 214, 0.4), rgba(193, 204, 214, 0.4)),
  linear-gradient(0deg, rgba(193, 204, 214, 0.2), rgba(193, 204, 214, 0.2));
  border: 1px solid #C1CCD666;


}
.ad-mdb-submit {
  width: 392px;
  height: 47px;
  padding: 16px 20px 16px 20px;
  border-radius: 8px;
  background-color: #8086FF;
  color: #ffffff;
  box-shadow: 0px 2px 2px 0px #00000033;
  border: none;
  outline: none;
  width: 100%;
}
.add-doc-top-btn {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 4px;
  padding-right: 19px;
  width: 135px;
  height: 33px;
  border: 2px solid #758AF0;
  border-radius: 4px;
  position: relative;
  transition: all .3s ease-in-out;
  background: #758AF0;
  color: #fff !important;
}

.add-doc-top-btn:hover {
  background: #758AF0;
  border: 2px solid #758AF0;
  color: #fff;
}
.add-doc-top-btn:before{
    content: "";
    background: url(../images/add.svg);
    position: absolute;
    top: 9%;
    left: 14px;
    width: 23px;
    height: 23px;
    display: inline-block;
    z-index: 99;
}
.add-doc-top-btn::before {
  background: url("../images/add-white.svg");
}
.add-new-folder .addemail {
  display: initial;
  padding-left: 40px;
  position: relative;
  transition: all .3s ease-in-out;
}
a.addemail:hover {
  background: #758AF0;
  border: 2px solid #758AF0;
  color: #fff;
}
.add-new-folder a:before {
  content: "";
  background-image: url(../images/add.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 3px;
  left: 8px;
  transition: all .3s ease-in-out;

}
.add-new-folder.addemail a:before:hover {
  background-image: url('../images/add-white.svg'); 
}

.btn-invite-Continue {
  margin-left: auto;
  width: 20%;
  margin-right: 11px;
}
.date-up {
  position: relative;
}
.date-up:before {
  content: "";
  background: url(../images/date-uploaded.svg);
  width: 16px;
  height: 8px;
  position: absolute;
  top: 7px;
  right: -28px;
}
table {
  border-collapse: collapse;
  border-collapse:separate;
  border-spacing:0 15px;
}

table, th, td {
  border: none;
}
td {
  margin-bottom: 10px;;
}
.document-heading {
  background-color: transparent;
  box-shadow: none;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #617D97;

}

table.add-documents tr td.folder-img {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #1A344D;
  padding: 10px 0 10px 55px;
  align-items: center;
  position: relative;
  width: 47%;
}
table.add-documents tr td.folder-img:before {
  content: "";
  background-image: url(../images/folder.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 7px;
  left: 22px;
}

td.file-size {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #1A344D;
}
.add-docs-tr {
  vertical-align: middle;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(15, 42, 66, 0.1);
}
table.table.add-documents {
  margin-bottom: 4px;
  cursor: pointer;
}
.add-docs-heading th {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #617D97;
  height: 50px;
}
.add-docs-heading th:first-child {
  padding-left: 40px;
}
.add-docs-heading th:nth-child(2){
  padding-left: 40px;
}
.add-docs-heading th:nth-child(4) {
  position: relative;
}
.add-docs-heading th:nth-child(4):after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/date-uploaded.svg) no-repeat;
  height: 8px;
  width: 16px;
}
.add-docs-heading th {
  height: 0px;
}
td.pdf a {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #1A344D;
}
.pdf {
  width: 12%;
}
/* .pdf::before {
  content: "";
  background-image: url(../images/pdf.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 12px;
  left: -24px;
} */
td.pdf img {
  width: 100%;
  max-width: 24px;
  height: 24px;
  line-height: 24px;
  padding-top: 5px;
}
.add-docs-progressbar {
  width: 50%;
  height: 7px;
}
td.folder-img {
  border-style: solid;
  border-width: 0;
  border-radius: 10px 0 0 10px;
}
.add-docs-tr td:last-child {
  border-style: solid;
  border-width: 0;
  border-radius: 0 10px 10px 0;
  width: 27%;
  padding: 0 24px;
}
td.file-size.toptwo {
  padding-left: 55px;
}
td.mySection {
  border-style: solid;
  border-width: 0;
  border-radius: 10px 0 0 10px;
  width: 42%;
  line-height: 230%;
}
.mySection {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #1A344D;
  padding-left: 20px !important;
  /*padding: 0px 0 0px 26px !important;*/
  align-items: center;
  position: relative;
}
td.file-size {
  padding-bottom: 0;
  padding-top: 0;
}
td.pdf {
  padding: 0;
}
/*--------------------------------------------Add Documents Section end----------------------------------------------*/


/*--------------------------------------------Ask Your First Question Section----------------------------------------------*/
.logo a {
  display: flex;
}
.logo img {
  width: 100%;
  max-width: 146px;
}
a.navbar-brand.py-0.mt-2 {
  margin-right: 40px;
}
.main-column {
  justify-content: space-between;
}
.user-profile-pic img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.chat-search {
  display: flex;
  align-items: center;
  padding: 10px;
}

.chat-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.add-chat {
  text-align: center;
  padding: 10px;
}
.top-org {
  position: relative;
  margin-left: 40px;
}
.chat-top-bar span.top-org a:not(:first-child) {
  padding-left: 40px !important;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #99AAFF;
}
.chat-top-bar span a:last-child{
  color: #C1CCD6;
}
.chat-top-bar img {
  margin-right: 6px;
}
.chat-top-bar .top-org img {
  margin-right: 6px;
  padding-left: 30px;
}
.top-org li {
  display: inline-block;
  position: relative;
  margin-right: 30px;
}
ul.navbar-nav p, i {
  color: #99AAFF;
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
ul.navbar-nav i {
  font-size: 22px;
} 
ul.top-org li a {
  color: #99AAFF;
  display: contents;
  font-size: 20px !important;
  font-weight: bold !important;
}
ul.top-org li.active a {
  color: #C1CCD6;
  font-size: 20px !important;
  font-weight: bold !important;
}
.nav-top-active {
  color: #C1CCD6 !important;

}
.top-menu {
  flex: auto;  
}
ul.top-org img {
  margin-right: 8px;
}

.dropdown-toggle::after {
  display: none;
}
li.signout-dropdown{ width: 50%;}

.toggle-closed-btn {
  background-image: url(../images/Close_toggle.svg);
  font-size: 32px;
  margin-left: -20px !important;
  margin-right: 7px !important;
  filter: brightness(1.2); 
}
.offcanvas-title-toggle {
  font-family: Red Hat Text;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

}
/*Chat box css*/
.chat-list {
  
  margin-top: 20px;
  background: #FFFFFF;
  /* box-shadow: 0px 2px 4px rgba(15, 42, 66, 0.1); */
  border-radius: 16px 16px 0px 0px;
  padding: 20px;
}

.chat-list input::placeholder {
  color: #819AB0;
  margin-left: 20px;
}
#inlineFormInputGroup::placeholder{
  text-align: center;
}
form.chat-list-left {
  width: 100%;
  text-align: left;
  position: relative;
}
.searchbar-padding {
  padding: 6px 0 6px 60px !important;
}
form.chat-list-left input {
  background: #E7EBEF;
  padding-left: 50px;
}
button:focus {
  outline: none;
  border: none;
}
.search-btn {
  position: absolute;
  top: 0;
  left: 0;
}
.search-bar {
  width: 100%;
  position: relative;
}
.search-bar:before {
  content: "";
  background: url(../images/Add.png) no-repeat;
  height: 33px;
  width: 36px;
  line-height: 27px;
  position: absolute;
  top: 5px;
  left: 7px;
}
.chat-list-left .form-control:focus {
  color: #212529;
  background-color: #E7EBEF;
  outline: none;
}
.option-img  {
   position: relative;
}
.option-img img {
  width: 100%;
  max-width: 200px;
  max-width: 30px;
  margin-left: -9px;
  position: absolute;
  top: -32px;
  right: 21px;
}
.left-container {
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(15, 42, 66, 0.1);
  border-radius: 16px 16px 0px 0px;
}
.today-border {
  border-bottom: 1px solid #C1CCD6;
}
.list-scroll {
  height: 80vh;
  overflow-y: scroll;
}
/* .sendbox {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
} */
.list-scroll::-webkit-scrollbar-track
{
	background-color: #DDE2E8;
}

.list-scroll::-webkit-scrollbar
{
	width: 16px;
	background-color: #C1CCD6;
}

.list-scroll::-webkit-scrollbar-thumb
{
	background-color: #C1CCD6;	
	background-image: #C1CCD6;
}
 
.chat_list_heading {
  font-family: 'Red Hat Text';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 130%;
letter-spacing: 0.1em;
text-transform: uppercase;
color: #819AB0;
margin-left: 10px;
}
a.new_chat {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #334E67;
  padding: 15px;
  width: 100%;
  display: block;
  background: #DBDFF0;
  border-radius: 8px;
}

.nc-top {
  width: 80%;
  /*padding: 15px;*/
  justify-content: space-between !important;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(15, 42, 66, 0.1);
  border-radius: 0px 0px 16px 16px;
}
.nc-top p {
  margin: 0 !important;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  /* identical to box height, or 21px */


color: #0F2A42;

}
.nc-top a {
    font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    color: #FFFFFF;
    padding: 7px 16px 7px 32px;
    background: #758AF0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: relative;

}
.nc-top a:before {
  content: "";
  height: 24px;
  width: 24px;
  background: url(../images/add-white.svg);
  background-repeat: no-repeat;
  position: absolute;
  top: 2px;
  left: 4px;
}
.Ask-your-first-question {
  min-height: 500px;
  background-color: transparent;
  color: #334E67;
}
.Ask-your-first-question img {
  margin-bottom: 20px;
}
.Ask-your-first-question h6 {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 120%;
  text-align: center;
  color: #334E67;
  margin-bottom: 20px;

}
.Ask-your-first-question p {
  width: 100%;
  max-width: 380px;
  text-align: center;
  margin-bottom: 20px;

}
.input-chat-mic {
  width: 82%;
  background: #FFFFFF;
  box-shadow: 0px 16px 16px rgba(15, 42, 66, 0.1);
 
}
textarea.form-control.mic-text-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 541px;
  height: 44px !important;
  background: #E7EBEF;
  border: 1px solid #DDE2E8;
  border-radius: 8px;
  margin-left: 3px;
  overflow: hidden;
  resize: none;
  }
input.form-control.mic-text-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 541px;
  height: 44px !important;
  background: #E7EBEF;
  border: 1px solid #DDE2E8;
  border-radius: 8px;
  margin-left: 3px;
  }
  .chat-btn {
    background: #758AF0 !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-family: 'Red Hat Text' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 110% !important;
    border: none !important;
    outline: none !important;
    height: 44px !important;
  }

  .send-btn {
    background: #758AF0 !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-family: 'Red Hat Text' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 110% !important;
    padding-left: 30px !important;
    border: none !important;
    outline: none !important;
    height: 44px !important;
  }
  
  .send-btn:hover, .send-btn:active{
    background: #758AF0 !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0px 8px 8px 0px;
    color: #fff;
    border: none;
  }
  .btn-submit {
    position: relative;
  }
  .btn-submit:after {
    content: "";
    background: url(../images/send.svg) no-repeat;
    height: 18px;
    width: 19px;
    position: absolute;
    top: 14px;
    left: 13px;
    z-index: 99;
}.btn-submit:after {
    content: "";
    background: url(../images/send.svg) no-repeat;
    height: 18px;
    width: 19px;
    position: absolute;
    top: 14px;
    left: 13px;
    z-index: 99;
}
.top-toggle {
  display: none;
}
.toggle-button {
  width: 100%;
  max-width: 50px;
  background: none;
  border: none;
  display: none;
}

/* audio player */

/*--------------------------------------------Ask Your First Question Section end----------------------------------------------*/





/*-------------------------------Chat Room---------------------------------*/
.search-option {
  background: none;
  border: none;
  padding: 4px;
  align-self: center;
}
.today {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #819AB0;
  border-bottom: 1px solid #C1CCD6;
  padding: 21px 0 3px 10px;
  margin-bottom: 5px;
}
button#Open-tab {
  background: #DBDFF0;
  border-radius: 8px;
  border-bottom: none;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #334E67;
  text-align: left;
}


.active-chat {
  background: #DBDFF0;
  border-radius: 8px;
  max-width: 95%;
  border-bottom: 0 !important;
}
/* **********************************
Reset CSS
************************************** */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1.5;
    /*overflow-y: auto !important;*/
}
.parent-body {
  overflow-y: auto !important;
}
.modal-body.over-hide-1::-webkit-scrollbar {
  width: 0 !important; /* Width of the scrollbar */
}
/* width */
::-webkit-scrollbar {
  width: 16px;
  scroll-padding-top : 50px !important;
  height: calc(100vh - 50px) !important;  
}

/* Track */
::-webkit-scrollbar-track {
  background: #E7EBEF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #C1CCD6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C1CCD6;
}
ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}


/********************************
 Typography Style
******************************** */

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-size: 38px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

p {
    font-size: 17px;
}

a {
    text-decoration: none;
    font-size: 15px;
}

* {
  margin-bottom: 0;
}


/* *******************************
message-area
******************************** */

.message-area {
    overflow: hidden;
    background: #f5f5f5 !important;
}

.chat-area {
  position: relative;
  width: 100%;
  border-radius: 0.3rem;
  height: calc(100vh - 50px);
}

.chatlist {
    outline: 0;
    height: calc(85vh - 50px);
    overflow-y: scroll;
    max-height: 55%;
    width: 300px;
    float: left;
    padding: 15px;
    padding-bottom: 0;
    background-color: #fff;
    margin-top: 5px;
    margin-left: 5px;
    box-shadow: 0px 2px 4px rgba(15, 42, 66, 0.1);
    border-radius: 4px 4px 0px 0px;
    scrollbar-width: thin;
}

.chat-area .modal-content {
    border: none;
    border-radius: 0;
    outline: 0;
    height: 96%;
}

.chat-area .modal-dialog-scrollable {
    height: 100% !important;
}

.iframe-container {
  background: #f5f5f5 !important;
}

.modal-body-over-hide {
  max-height: 1000px !important; /* Adjust this value as needed */
  overflow-y: auto !important;
}

.chatbox {
    width: auto;
    overflow: hidden;
    height: 100%;
}

.chatbox .modal-dialog,
.chatlist .modal-dialog {
    max-width: 100%;
    margin: 0;
}
.modal-body.over-hide {
  overflow: hidden;
}
.modal-body img{
  max-width: 600px;
  display: block;  
}
.modal-body.over-hide-1 {
  overflow-y: scroll;
  height: 100vh;
}

.popup-modal {
  position: fixed;
  top:15% ;
  left: 40%;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  width: 100%;
  max-width: 472px;
  margin: 0 auto;
  box-shadow: 0px 16px 16px 0px #0F2A421A;
  /* max-width: fit-content; */
  padding: 2rem;

}

/* .modal-content {
  display: flex;
  flex-direction: column;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: 1 solid var(#000);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
} */


.msg-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chat-area .form-btn {
  display: block;
  padding: 0.375rem 0.75rem;
  background-color: #E7EBEF;
  background-clip: padding-box;
  border: 1px solid #C1CCD6;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #819AB0;
  border-radius: 8px;
  border: 1px;
  cursor: pointer;
}

.chat-area .form-control {
  display: block;
  padding: 0.375rem 0.75rem;
  background-color: #E7EBEF;
  background-clip: padding-box;
  border: 1px solid #C1CCD6;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 8px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #819AB0;
  border-radius: 8px;
  border: 1px
}
button.add.search-option img {
  margin-left: 10px;
}
.chat-area .form-control:focus {
    outline: 0;
    box-shadow: inherit;
}

a.add img {
    height: 36px;
}

.chat-area .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    align-items: center;
    justify-content: space-between;
    flex-wrap: inherit;
}

.chat-area .nav-tabs .nav-item {
    width: 100%;
    cursor: pointer;
}

.chat-area .nav-tabs .nav-link {
    width: 100%;
    color: #180660;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.chat-area .nav-tabs .nav-item.show .nav-link,
.chat-area .nav-tabs .nav-link.active {
    color: #222;
    background-color: #fff;
    border-color: transparent transparent #000;
    height: 48px !important;
    cursor: pointer !important;
}

.chat-area .nav-tabs .nav-link:focus,
.chat-area .nav-tabs .nav-link:hover {
    border-color: transparent transparent #000;
    isolation: isolate;
    height: 48px !important;
}

.chat-list a {
    color: #222;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
    font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #334E67;

}

.chat-list p {
    color: #343434;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.chat-list a.d-flex {
    margin-bottom: 15px;
    position: relative;
    text-decoration: none;
}

.chat-list .active {
    display: block;
    content: '';
    clear: both;
    position: absolute;
    bottom: 3px;
    left: 34px;
    height: 12px;
    width: 12px;
    background: #00DB75;
    border-radius: 50%;
    border: 2px solid #fff;
}

.msg-head h3 {
    color: #222;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0;
}

.msg-head p {
    color: #343434;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
    font-family: 'Red Hat Text';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #0F2A42;
}


.moreoption {
    display: flex;
    align-items: center;
    justify-content: end;
}

.moreoption .navbar {
    padding: 0;
}

.moreoption li .nav-link {
    color: #222;
    font-size: 18px;
}

.moreoption .dropdown-toggle::after {
    display: none;
}

.moreoption .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: auto;
    right: 0;
    margin-top: 0.125rem;
}
.content .slide {
  min-width:100vw;
  min-height:100vh;
}
/*#chatContainer {
  background-color: white;
}*/

.msg-body ul {
    overflow: hidden;
}

.msg-body ul li {
    list-style: none;
    margin: 15px 0;
}

.msg-body ul li.sender {
    display: block;
    width: 100%;
    position: relative;
}
.senderplay img:first-child {
  height: 22px;
  width: 22px;
}
.msg-body ul li.sender:before {
  display: block;
  clear: both;
  content: '';
  position: absolute;
  top: 0px;
  right: 50px;
  width: 33px;
  height: 24px;
  /* border-style: solid; */
  border-width: 23px 32px 0px 0px;
  border-color: #d9f2e5 #fff0 #fff0 #fff;
  -webkit-transform: rotate(-37deg);
  -ms-transform: rotate(-37deg);
  transform: rotate(360deg);
  color: #fff;
}

.msg-body ul li.sender p {
    color: #334E67;
    font-size: 17px;
    line-height: 1.5;
    font-weight: 400;
    padding: 15px;
    background: #D9F2E5;
    display: inline-block;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-bottom: 0;
    text-align: left;
    box-shadow: 1px 3px 6px #d5d5d5;
}
.msg-body ul li.sender p b {
    display: block;
    color: #180660;
    font-size: 17px;
    line-height: 1.5;
    font-weight: 500;
}

.msg-body ul li.repaly {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
}
.msg-body ul li.repaly:before {
  display: block;
  clear: both;
  content: '';
  position: absolute;
  top: -3px;
  left: 36px;
  width: 15px;
  height: 9px;
  /* border-style: solid; */
  border-width: 0 12px 15px 12px;
  border-color: transparent transparent #ffffff transparent;
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  transform: rotate(410deg);
}
/* .msg-body ul li.repaly p {
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: #D9F2E5;
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 0;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  background: #fff;
  line-height: 130%;
  color: #334E67;
  text-align: left;
  box-shadow: -2px 3px 6px #d5d5d5;
} */

.msg-body ul li.repaly p b {
    display: block;
    color: #061061;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
}

.msg-body ul li.repaly:after {
    display: block;
    content: '';
    clear: both;
}

.time {
    display: block;
    color: #000;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
}

li.repaly .time {
    margin-right: 20px;
}
li.repaly img, li.sender img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
li.sender img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: 19px;
}
.divider {
    position: relative;
    z-index: 1;
    text-align: center;
}

/* .msg-body h6 {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #222;
    background: #fff;
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 0;
} */

.divider:after {
    display: block;
    content: '';
    clear: both;
    position: absolute;
    top: 12px;
    left: 0;
    border-top: 1px solid #EBEBEB;
    width: 100%;
    height: 100%;
    z-index: -1;
}


.send-box form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.send-box .form-control {
    display: block;
    width: 85%;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #222;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.send-box button {
    border: none;
    background: #3867d6;
    padding: 0.375rem 5px;
    color: #fff;
    border-radius: 0.25rem;
    font-size: 16px;
    font-weight: 400;
    width: 24%;
    margin-left: 1%;
}

.send-box button i {
    margin-right: 5px;
}

.send-btns .button-wrapper {
    position: relative;
    width: 125px;
    height: auto;
    text-align: left;
    margin: 0 auto;
    display: block;
    background: #F6F7FA;
    border-radius: 3px;
    padding: 5px 15px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    overflow: hidden;
}

.send-btns .button-wrapper span.label {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    color: #343945;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 15px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.send-btns .attach .form-control {
    display: inline-block;
    width: 120px;
    height: auto;
    padding: 5px 8px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #343945;
    background-color: #F6F7FA;
    background-clip: padding-box;
    border: 1px solid #F6F7FA;
    border-radius: 3px;
    margin-bottom: 5px;
}

.send-btns .button-wrapper span.label img {
    margin-right: 5px;
}

.button-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    margin: 0 auto;
}

button:focus {
    outline: 0;
}

.add-apoint {
    display: inline-block;
    margin-left: 5px;
}

.add-apoint a {
    text-decoration: none;
    background: #F6F7FA;
    border-radius: 8px;
    padding: 8px 8px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
    color: #343945;
}

.add-apoint a svg {
    margin-right: 5px;
}

.chat-icon {
    display: none;
}

.closess i {
    display: none;
}

/*---------------------chat gpt toggle------------------*/

/* Styles for screens 768px and above */
@media (min-width: 768px) {
  .toggle-menu {
      display: none; /* Hide the toggle menu icon */
  }
  /* Add additional styles for the top bar */
}

/* Styles for screens below 768px */
@media (max-width: 767px) {
  .chat-header {
      position: relative; /* Ensure the menu content overlays properly */
  }
  .toggle-menu {
      position: absolute;
      top: 0;
      left: 10px; /* Adjust the positioning as per your design */
      padding: 10px;
      background-color: transparent;
      border: none;
      z-index: 999;
  }
  .toggle-menu img {
      width: 24px;
      height: 24px;
  }
}


/*---------------------chat gpt toggle end------------------*/

@media (max-width: 767px) {
    .chat-icon {
        display: block;
        margin-right: 5px;
    }
    .chatlist {
        width: 100%;
        height: 100vh;
    }
    .chatbox {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        background:#f5f5f5;
        transition: all 0.5s ease;
        border-left: none;
    }
    .showbox {
        left: 0 !important;
        transition: all 0.5s ease;
    }
    .msg-head h3 {
        font-size: 16px;
    }
    .msg-head p {
        font-size: 14px;
    }
    .msg-head .flex-shrink-0 img {
        height: 30px;
    }
    .send-box button {
        width: 28%;
    }
    .send-box .form-control {
        width: 70%;
    }
    .chat-list h3 {
        font-size: 16px;
    }
    .chat-list p {
        font-size: 12px;
    }
    .msg-body ul li.sender p {
        font-size: 15px;
        padding: 8px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .msg-body ul li.repaly p {
        font-size: 15px;
        padding: 8px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}
/*-------------------------------Chat Room End---------------------------------*/




/*----------------------------- Collection Name 1 Section -----------------------------*/
.cn-title p{
font-family: Red Hat Text;
font-size: 20px;
font-weight: 600;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #617D97;
}


.cn-content p{
font-family: Red Hat Text;
font-size: 20px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #617D97;
}
.ads-title h1 {
  font-family: Red Hat Text;
  font-size: 26px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #334E67;
  margin-left: 5px;
}
.ads-title span {
font-family: Red Hat Text;
font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
}
.anchor-margin a {
  margin-right: 14px;
  color: #8086FF;
}
.ps-btn-left {
  margin-left: 40px;
}
.anchor-margin a img {
  margin-top: 8px;
}
.bulk {
font-family: Red Hat Text;
font-size: 18px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
}

.ps-butn {
  color: #fff !important;
  background-color: #8086FF;
  font-family: Red Hat Text;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 170px;
  height: 34px;
  padding: 5px 16px 5px 12px;
  border: 2px solid #758AF0;
  border-radius: 4px;
  position: relative;
  transition: all .3s ease-in-out;
}
.ps-butn-1{
  width: 128px;
  height: 34px;
}
.ps-butn :hover {
  background: #758AF0;
  border: 2px solid #758AF0;
  color: #fff;
}
.ps-butn:before{
  content: "";
  background: url(../images/add-white.svg);
  position: absolute;
  top: 9%;
  left: 14px;
  width: 23px;
  height: 23px;
  display: inline-block;
  z-index: 99;
}
.ps-butn-1:before {
  left: 8px;
}
.ps-butn:hover::before {
  background: url("../images/add-white.svg");
}
.ads-search{
width: 342px !important;
height: 37px !important;
padding: 6px 12px 7px 12px;
border-radius: 8px;
border: 1px solid #C1CCD6;
background-color: #E7EBEF;
color: #C1CCD6;
}
.ads-search:focus {
  border: 1px solid #C1CCD6;
  outline: none; 
  background-color: #E7EBEF !important;
}

.search-container {
  position: relative;
  width: 100%;
  max-width: 342px;
  margin-left: auto;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px; /* Adjust the width and height as per your image dimensions */
  height: 20px;
}

.ads-search {
  padding-left: 40px !important;
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
 /* Adjust the padding value to align the placeholder with the image */
}
.ads-search::placeholder{
  margin-top: 5px;
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #819AB0;
}
.ads-search input:focus{
  color: #819AB0;
}

.cn-content.nav-link:hover .chat-svg path, .chat-svg circle {
  stroke: #8086FF !important;
  fill: #8086FF;
}


.cn-content:hover p,
.cn-content.nav-link:hover svg path,
.cn-content.nav-link:hover svg ellipse,
.cn-content.nav-link:hover svg circle,
.cn-content.nav-link:hover svg stroke {
     stroke: #8086FF; /* Change the color to the desired one */
     color: #8086FF;
}
.nav-link.active.d-flex.cn-content {
  border-bottom: 3px solid transparent; /* Set a fixed border size */
  transition: border-color 0.3s; /* Add a transition effect */
  height: 48px !important;
}

.nav-link.active.d-flex.cn-content:hover {
  border-color: #8086FF; /* Change the border color on hover */
}
.nav-link.active.d-flex.cn-content:hover path, .nav-link.active.d-flex.cn-content:hover circle {
  border-color: #8086FF; /* Change the border color on hover */
  stroke: #8086FF !important;
}
.nav-link.active.d-flex.cn-content:active {
  border-color: #8086FF; /* Change the border color on hover */
}
.fz-width{
  width: 50%;
}
.edit-deleteHover{
  display: inline-block;
  text-align: right;
  width: 48%;
}
.edit-table {
  background-color: #ffffff;
  transition: background-color 0.3s;
  cursor: pointer;
}

.edit-table:hover {
  background-color: #F5F6FF; /* Change the background color on hover */
}

.user-icon-margin {
  margin-right: 122px !important; /* Adjust the margin as needed */
}

.icon-row {
  display: flex !important;
  float: right !important;
}


.edit-table .edit-imgHover img,
.edit-table .edit-deleteHover img {
  margin-left: 15px;
  opacity: 0; /* Start with 0 opacity to hide the image */
  transition: opacity 0.3s; /* Add a transition effect for a smooth fade-in */
  vertical-align: middle;
}

.edit-table:hover .edit-imgHover img,
.edit-table:hover .edit-deleteHover img {
  opacity: 1; /* Change opacity to 1 on hover to make the image visible */
}

/*-----------------------------End Collection Name 1 Section -----------------------------*/

/*----------------------------- Delete Selected Section -----------------------------*/

.ds-btn {
  width: 159px;
  height: 34px;
  border-color: #E47C67;
}
.anchor-margin a.ds-btn {
  color: #E47C67;
  width: 160px !important;
  height: 35px !important;
}

.ds-btn:before {
  content: "";
  background: url(../images/Delete.png);
  position: absolute;
  top: 9%;
  left: 7px;
  width: 23px;
  height: 23px;
  display: inline-block;
  z-index: 99;
}
.anchor-margin a.ds-btn:hover {
  color: #fff;
  background-color: #E47C67;
  border-color:  #E47C67;;
}
.ds-btn:hover::before {
  background: url(../images/Delete.svg) no-repeat;
}





.cbe-btn {
  width: 160px;
  height: 35px;
  border-color: #8086FF;
}
.anchor-margin a.cbe-btn{
  color: #8086FF;

}
.cbe-btn:before {
  content: "";
  background: url(../images/Close.png);
  position: absolute;
  top: 9%;
  left: 7px;
  width: 23px;
  height: 23px;
  display: inline-block;
  z-index: 99;
}
.anchor-margin a.cbe-btn:hover {
  color: #fff;
  background-color: #8086FF;
  border-color:  #8086FF;
}
.cbe-btn:hover::before {
  background: url(../images/Close_white.svg) no-repeat;
}

.cbe-btn {
  margin-right: 0 !important;
}
.cbe-position-relative {
  position: relative;
}

.checkbox-custom {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-image: url('../images/Unchecked.svg');
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  margin-right: 10px;
}

input[type="checkbox"] {
  position: absolute;
  height: 18px;
  width: 18px;
  visibility: hidden;
}

input[type="checkbox"]:checked + .checkbox-custom {
  background-image: url('../images/Checked.svg');
}

table.add-documents tr td.folder-img1 {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #1A344D;
  padding: 10px 0 10px 15px;
  align-items: center;
  position: relative;
  width: 47%;
}
.vertical-align-middle img{
  vertical-align: middle !important;
}

.ds-cbe-checkFN {
  width: 42% !important;
}
/*----------------------------- Delete Selected Section -----------------------------*/


/*----------------------------- Users Page -----------------------------*/
.navActive p,
.navActive svg path,
.navActive svg ellipse,
.navActive svg circle,
.navActive svg stroke {
     stroke: #8086FF; /* Change the color to the desired one */
     color: #8086FF;
     border-bottom: 3px solid #8086FF;
}

.user-sbar {
  text-align: right !important;
  text-align: -webkit-right !important;
}

.user-sbar-i {
  position: absolute !important;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.users {
  padding-left: 20px !important;
}
.user-pp {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  vertical-align: middle;
}
td.user-ms {
  padding: 5px 0 5px 18px  !important;
}
.permission p {
  padding-left: 65px;
  font-family: Red Hat Text !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

}
.date-added:before {
    content: "";
    background: url(../images/date-uploaded.svg);
    width: 16px;
    height: 8px;
    position: absolute;
    top: 7px;
    right: 50px;
}
.date-added p {
  padding-right: 38px;
  font-family: Red Hat Text !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}
.userDates {
  padding: 0 51px 0 0 !important;
}
.user-name {
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #1A344D;
  padding-left: 15px;
}
section#collectName1 {
  box-shadow: 0px 2px 4px 0px #0F2A421A;
  /*height: 60px;*/
}
.userType p {
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #1A344D;
  text-align: center;
}
p.date-up.date-added {
  text-align: center;
  padding-right: 50px;
}
.usertablemrgn {
  margin-bottom: 0 !important;
}
/*----------------------------- End Users Page -----------------------------*/


/*----------------------------- Organization Page -----------------------------*/
p.ms-2.org-top-nav {
  color: #C1CCD6;
}
.org-heading span {
  font-family: Red Hat Text;
  font-size: 26px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #617D97;
}

.org-heading h1 {
  font-family: Red Hat Text;
  font-size: 26px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}
.add-org-btn {
  
  width: 171px;
  height: 34px;
  padding: 5px 16px 5px 12px;
  border-radius: 4px;
}
.orgRow-left h1 {
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #334E67;
}
/*.inner-container {
  width: 100%;
  max-width: 829px;
  height: 88px;
  padding: 12px 20px 12px 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #0F2A421A;

  }*/
.orgRow-left p {
  font-family: Red Hat Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #617D97;
}
.org-li h6 {
  font-family: Red Hat Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: Grey 5;
}
.org-li h5 {
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: Grey 8;
}
.orgRow-right ul li {
  margin-right: 30px;
  
}
.orgRight-row {
    width: 100%;
    max-width: 850px;
    min-height: fit-content;
    padding: 12px 20px 12px 20px;
    border-radius: 8px;
    /*gap: 40px;*/
    justify-content: space-between;
    box-shadow: 0px 2px 4px 0px #0F2A421A;
    margin-bottom: 9px;
}
.action-btn a {
  opacity: 0;
  transition: all .3s ease-in-out; 
}
.orgRight-row:hover .action-btn a {
  opacity: 1;
}
.orgRight-row {
  transition: all .3s ease-in-out; 
  cursor: pointer;
}
.orgRight-row:hover {
  background-color: #F5F6FF !important;
}
.icon-margin-right {
  margin-right: 28px !important; /* You can adjust the margin as needed */
}


/*-----------------------------End Organization Page -----------------------------*/



/*-----------------------------Organization Name Page -----------------------------*/

.add-collection {
  width: 210px;
  margin-left: 20px;
}
.add-collection:hover {
  box-shadow: 0px 2px 2px 0px #00000033;
}

.addCollections a {
  display: inline-block;
}
a.org-name {
  text-decoration: none;
}
a.back-circle {
  margin-top: 8px;
}
.cn-Padding {
  padding-left: 25px !important;
}
.cnDocs-padding {
  padding-left: 89px;
}
.cnUser-padding {
  padding-left: 50px !important;
}
/*-----------------------------Organization Name Page -----------------------------*/



.admin-chat-pos {
  box-shadow: 0px 2px 4px 0px #0F2A421A;
  margin-bottom: 0px;
  z-index: 999;
  position: relative;
}


/*--------------------------- - ---------------------------*/
.adu-user-button {
  width: 116px !important;
  height: 34px !important;
}


.adu-body {
box-shadow: 0px 2px 4px 0px #23272b1a !important;
width:1260px;
min-height:48px;
border-radius: 8px;


}
.adu-heading {
font-family: Red Hat Text;
font-size: 18px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #617D97;
}
.adu-align p {
  vertical-align: middle !important;
}
.adu-align {
  padding-left: 18px !important;
}
.adu-left-rad {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
.adu-right-rad {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.adu-radio {
  margin-right: 0 !important;
}
.adu-right-rad a {
  opacity: 0 !important;
  width: 30%;
}

.adu-left-rad a:not(:first-child) {
  opacity: 0 !important;
}

.adu-body a {
  transition: 0.3s all ease-in-out;
}

.adu-body:hover a {
  opacity: 1 !important;
}
.adu-user-delete {
  justify-content: space-between;
}
.adu-user-delete a {
  opacity: 0;
}
.adu-body:hover .adu-user-delete a {
  opacity: 1;
}
.adu-body:hover {
  background-color: #f5f6ff !important;
  cursor: pointer;
}
.adu-right-rad img {
  vertical-align: middle;
}
.adu-email {
font-family: Red Hat Text;
font-size: 18px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #1A344D;

}
.adu-right-rad p {
font-family: Red Hat Text;
font-size: 18px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #1A344D;
}
.date-arrow {
  position: relative;
}
.date-arrow:before {
  content: "";
  background: url(../images/date-uploaded.svg);
  width: 16px;
  height: 8px;
  position: absolute;
  top: 7px;
  left: 100px;
}

.adu-user-delete p {
    font-family: Red Hat Text;
    font-size: 18px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #1A344D !important;
}


/*--------------------------------------------------------------*/
.multiselectheading div {
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #617D97;

}
.adu-select {
  padding-top: 11px !important;
  max-width: 284px;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  border: 1px solid #C1CCD666;
  background: #C1CCD633;
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #1A344D;
  position: relative;
}



.checkboxvisible {
  visibility: 0 !important;
}


/*-------------------------------Existimg User Pop-up-------------------*/

.user-pop-heading {
  padding-left: 50px;
}
.Role-pop-heading {
  padding-left: 18px;
}

.adu-body-1 {
  box-shadow: 0px 2px 4px 0px #23272b1a !important;
  width: 1260px;
  min-height: 48px;
  border-radius: 8px;
}

a.invite-user-popup {
  width: 245px;
  height: 47px;
  padding: 16px 20px 16px 20px;
  border-radius: 8px;
}

.radio3 {
  margin-right: 0 !important;
}
.email2 {
  display: flex;
}
.email2 input[type=email] {
  width: 279px;
  height: 37px;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(193, 204, 214, 0.4), rgba(193, 204, 214, 0.4));
  border: 1px solid #C1CCD666;
  align-self: center;
}

.add-user-choose {
  border: none;
}

.add-existingUsers, .add-newUsers{
  font-family: Red Hat Text !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #334E67 !important;

}
.add-existingUsers:hover, .add-newUsers:hover {
  background-color: #fff;
}
.add-existingUsers:focus, .add-newUsers:focus {
  background-color: #fff;
}
body.modal-open{
  padding-right: 0px !important;
}
.addUserwidth {
  width: 100% !important;
  max-width: 245px !important;
  align-items: center;
  display: flex;
  justify-content: center;
}
.addUserwidth:hover {
  color: #fff;;
}
/*-------------------------------End Existimg User Pop-up-------------------*/




/*-------------------------------Profile Page-------------------*/
.p-profile {
  font-family: Red Hat Text;
  font-size: 26px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #334E67;
  margin-top: 40px;
  margin-bottom: 28px;
}
.pro-img img {
  width: 100% !important;
  max-width: 120px !important;
  margin-bottom: 10px;
  cursor: pointer;
}
.pp-username {
  font-family: Red Hat Text;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.pp-text {
  width: 100% !important;
  max-width: 392px !important;
  height: 45px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(193, 204, 214, 0.4), rgba(193, 204, 214, 0.4)),
  linear-gradient(0deg, rgba(193, 204, 214, 0.2), rgba(193, 204, 214, 0.2));
  border: 1px solid #C1CCD666;

}
.upload-label {
  font-family: Red Hat Text;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center !important;
  color: #758AF0;
  cursor: pointer;
}
.pp-inputWidth {
  width: 100% !important;
  max-width: 392px !important;
  margin-left: 20px !important;

}
/*-------------------------------End Profile Page-------------------*/


/*-------------------------------Profile 2 Page-------------------*/

.profile2 nav {
  height: 100vh !important;
  border-right: 1px solid #C1CCD6;
  vertical-align: middle;
  text-align: center;
  padding-top: 97px;
}

.pp2-sidebar ul li.active {
  background-color: #DBDFF0;
  font-weight: 600;

}
.pp2-sidebar ul li {
  width: 243px;
  height: 37px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
}
.pp2-sidebar ul li a {
  font-family: Red Hat Text;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #334E67;
  display: block;
}

/*-------------------------------End Profile 2 Page-------------------*/


.pf-billing-head h6 {
font-family: Red Hat Text;
font-size: 24px;
font-weight: 600;
line-height: 31px;
letter-spacing: 0em;
text-align: left;
}
.pf-billing-head a {
font-family: Red Hat Text;
font-size: 18px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: left;

}
.billing-content a {
font-family: Red Hat Text;
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: center;
color: #617D97;
}
.billing-content a:nth-child(2){
font-size: 18px;
font-weight: 600;
color: #1A344D;
}
.billing-content span {
font-family: Red Hat Text;
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
}
.billing-content span:nth-child(2) {
font-size: 18px;
font-weight: 600;
line-height: 21px;
}
.billing-button a {
  padding: 9px 27px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px #0F2A421A;
  color: #8086FF;
  border: 2px solid #758AF0;
  font-family: Red Hat Text;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  transition: all .3s ease-in-out;
}
.billing-button a:hover {
  color: #fff;
  background-color: #758AF0;
}

.modal-container {
  position: fixed;
  top:30%;
  left : 50%;
  transform : translate(-50%,-50%);
  background-color: #00B277;
  min-width: fit-content;
  height: fit-content;
  border: #000;
  border-width: 1px;
  border-radius: 5px;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-content-invite {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: 1.5rem;
  outline: 0;
}

.add-doc-modal-width {
  width: 100%;
  max-width: 472px;
  margin: 0 auto;
  box-shadow: 0px 16px 16px 0px #0F2A421A;
}

.add-doc-pop-title {
  border-bottom: none !important;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 2rem;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.add-doc-pop-title h5 {
  font-family: Red Hat Text !important;
  font-size: 26px !important;
  font-weight: 600 !important;
  line-height: 31px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #334E67;
}



.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.align-items-center {
  align-items: center !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

@media (max-width: 500px){
  .org-heading h1 {
    text-align: center;
    margin-bottom: 10px;
    }
}

@media (max-width: 768px) 
{
  .orgRight-row {
    margin-bottom: 10px !important;
}

}

@media (max-width: 768px){
  .orgRight-row {
    max-width: 1260px !important;
    margin: 0 !important;
}
}

.btn-sent {
  background: #758AF0;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #FFFFFF;
  justify-content: center;
  padding: 16px 20px;
  width: 100%;
  display: block;
  text-align: center;
  height: fit-content;
  border: 1px solid #758AF0 !important;
}

.repaly-box{
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: #D9F2E5;
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 0;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  background: #fff;
  line-height: 130%;
  color: #334E67;
  text-align: left;
  box-shadow: -2px 3px 6px #d5d5d5;
  margin-left: 10px;
  margin-right: 10px;
}

.audio-recorder {
  height: fit-content !important;  
}

.recording {
  width: 85% !important;
  padding: 6px !important;
  margin-left: 20px !important;
  background: #E7EBEF !important;
  border: 1px solid #DDE2E8 !important;
  border-radius: 8px!important;
  height: 35px;
}

.recording .audio-recorder-mic {
  display: none;
}

.recording .audio-recorder-options:nth-child(even) {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url(../images/Pause.svg) 
    no-repeat !important;
  padding-right: 16px !important;
}

.recording .audio-recorder-options:nth-child(odd) {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url(../images/AudioDelete.svg) 
    no-repeat !important;
  padding-right: 16px !important;
}
ant-table-thead tr  th {
  color: #617D97 !important;
 }

.table-heading {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #1A344D;
}
.ant-table-thead .ant-table-cell {
  background-color: #F3F5F7 !important;
  color: #617D97 !important;
}

.ant-table-tbody .ant-table-row {
  height: 40px !important;
  /*display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  gap: 40px;
  width: 100%;;
  max-width: 1260px;
  height: 53px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(15, 42, 66, 0.1);*/
  border-radius: 8px;
}
.form-color-bg {
  background-color: #C1CCD666 !important;
  font-family: 'Red Hat Text';
}

.table-context-text {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1A344D;
}

.document-image {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.email-button-text {
  font-family: Red Hat Text;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #6369E9;
}
.linkDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.footer-div {
  padding: 0.2rem;
  min-width: 100%;
  background-color: #334E67;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
}

.content-container {
  overflow-y: auto;
  padding: 1rem;
  max-height: calc(100vh - 32vh);
}

.footer-text {
  font-size: 14px;
  color: #fff;
  font-family: Red Hat Text;
}

.footer-text:hover {color: #6369E9}

.footer-end-text {
  font-size: 12px;
  color: #d5d5d5;
  font-family: Red Hat Text;
}
.reply-message-img {
  height: 150px !important;
  width: 250px !important;
  border-radius: 0px !important;
}
.repaly-box {
  .ant-image {
    display: block !important;
    height: 150px !important;
    width: 250px !important;
  }
}

.user-heading-text {
  font-family: Red Hat Text;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
 color: #617D97 !important ; 
}

.invite-btn {
  display: flex;
flex-direction: row-reverse;
background-color: #fff;
width: 245px;
padding: 16px 20px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: var(--8086-ff, #758AF0);
box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.20);
}

.chat-list-btn {
  background-color: #61CA96 !important;
  border-radius: 16px !important; 
}

.collection-container .ant-popover-content {
  width: 534px;
  height: 360px;
  padding: 16px, 20px, 16px, 20px;
  border-radius: 12px;
  gap: 4px;
}  
.ant-menu-inline {
  width: auto !important
}
.ant-popover-inner-div {
  max-height: 287px !important;
  overflow-y: auto !important;
}

.ant-popover-content {
  .ant-table-thead {
    display: block !important;
  }
  .search-container-icon {
    position: relative;
  }
  .ant-table-container {
    width: 480px !important;
  }
  .ant-table-cell {
    background-color: white !important;
  }
  .child-row {
    height: 37px !important;
    padding: 8px 0px 8px 0px;
    font-family: Red Hat Text;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1A344D;
    cursor: pointer;
  }
  .search-txt {
    max-width: 500px !important;
    width: 494px !important;
  }
  .ant-table-tbody > tr >td {
    padding: 4px !important;
  }
  .tbl-header {
    color: #617D97 !important;
    font-family: Red Hat Text;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
  .expand-icon {
    cursor: pointer !important;
    text-align: end !important;
  }
  
}

.notes-list {
  width: 400px;
  max-height: 200px;
  overflow-y: auto;
  color: #222;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #334E67;
  padding: 4px 4px;
}

.today-border-none {
  border-bottom: none !important;
}

.iframe-header {
  height: 50px;
}

.iframe-header-mobile {
  height: 65px;
}

.iframe-collection-title {
  font-family: Red Hat Text;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: '#0F2A42 !important';
}

.iframe-collection-sub-title {
  font-family: Red Hat Text;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: '#617D97 !important';
}

.iframe-power-text {
  font-family: Red Hat Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: '#617D97 !important';
}

.sticky-footer {
  position: fixed;
  width: 75%;
  bottom: 0;
  background-color: #f5f5f5 !important
}

.sticky-footer-full-width-no-chat {
  bottom: 0px;
  position: relative;
}

.sticky-footer-full-width-chat {
  bottom: 0px;
  position: relative;
}

.table-list {
  bottom: 1.5rem;
  position: relative;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 0px !important;
}

.ant-upload-wrapper .ant-upload-drag {
  border: none !important;
}

.btn-sent:hover {
  color: white !important;
}

.formcollection-inner input[type=text] {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  /*width: 100%;*/
  width: 545px;
  height: 37px;
  background: rgba(193, 204, 214, 0.2);
  border: 1px solid rgba(193, 204, 214, 0.4);
  border-radius: 4px;
}
.email-link {
  font-family: Red Hat Text;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #6369E9;
}
.text-cls {
  color: #1A344D !important;
  
font-family: Red Hat Text;
font-size: 16px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;

}

.flex-parent-element {
  display: flex;
  width: 100%;
}

.flex-child-element {
  flex: 1;
  border: 2px solid rgb(117, 138, 240);
  margin: 10px;
  border-radius: 4px;
}

.flex-child-element:first-child {
  margin-right: 20px;
}

.flex-child-element-width-document {
  border: 2px solid rgb(117, 138, 240);
  margin: 10px;
  border-radius: 4px;
  width: 40%;
}

.flex-child-element-width-document:first-child {
  margin-right: 20px;
}

.p-3 {
  padding: 1rem 1rem 1rem 0rem !important;    
}
.anticon-reload {
  svg {
    font-size: 20px;   
    margin-right: 24px;
    cursor: pointer;
    text-align: right;
  }
}
.question-item {
  text-align: 'left';
  font-style: 'italic';
  cursor: pointer;
  color: '#758AF0';
 
  border-radius: 10px;
  padding: 5px;

}
